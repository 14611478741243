/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {SyntheticEvent, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import Card from '@components/card/Card';
import useInputField from '@hooks/useInputField';
import {storeInterface} from '@interfaces';
import {handleLoginRequest} from '@store/slices/userSlice';

import styles from './Login.module.css';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const user = useSelector((store: storeInterface) => store.user);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        dispatch(handleLoginRequest({
            mail: username.value,
            password: password.value
        }));
    };

    useEffect(() => {
        if (user.isLoggedIn) {
            navigate('/');
        }
    }, [user]);

    const {
        userValue: username,
        handleValueChange: handleUsernameChange,
        handleValueReset: handleUsernameReset,
        buildInputFieldCssClasses: buildUsernameCssClasses
    } = useInputField((value: string) => value.includes('@'));

    const {
        userValue: password,
        handleValueChange: handlePasswordChange,
        handleValueReset: handlePasswordReset,
        buildInputFieldCssClasses: buildPasswordCssClasses
    } = useInputField((value: string) => value.trim().length >= 6);

    const isFormValid = username.valid && password.valid;

    return (
        <div id={styles.loginPageContainer}>
            <div id={styles.loginPageContentContainer}>
                <Card>
                    <form onSubmit={handleSubmit} className={styles.loginForm}>
                        <input
                            type="text"
                            className={buildUsernameCssClasses(username)}
                            value={username.value}
                            onChange={handleUsernameChange}
                            onClick={handleUsernameReset}
                            autoFocus={true}
                        />
                        <input
                            type="password"
                            className={buildPasswordCssClasses(password)}
                            value={password.value}
                            onChange={handlePasswordChange}
                            onClick={handlePasswordReset}
                        />
                        <button className={styles.loginInput + ' ' + styles.loginInputSubmit} disabled={!isFormValid}>
                            Login
                        </button>
                    </form>

                </Card>
            </div>
        </div>
    );
}

export default Login;