/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import {tableProps} from '@interfaces';

import styles from './Table.module.css';

function Table({data, sortByName, children}: tableProps) {
    let numberOfRows = 0;
    data.forEach((category) => {
        if (category.items.length > numberOfRows) {
            numberOfRows = category.items.length;
        }
        if (sortByName) {
            category.items.sort((a: { text: string }, b: { text: string }) => {
                return a.text > b.text ? 1 : -1;
            });
        }
    });

    const getTitlesFromArray = () => {
        const titles = data.map((category) => {
            if (category.title) {
                return <th key={category.title} className={styles.lightCell} role={'tableHeader'}>{category.title}</th>;
            }
            return <th key={category} className={styles.lightCell} role={'tableHeader'}/>;
        });
        return <tr role={'tableRow'} className={styles.tableHeader}>{titles}</tr>;
    };

    const getRowsFromArray = () => {
        const tableRows = [];
        let rowIndex = 0;
        for (let i = 0; i < numberOfRows; i += 1) {
            // eslint-disable-next-line no-loop-func
            const rows = data.map((category, index) => {
                if (category.items[rowIndex]) {
                    return (
                        <td key={index} className={styles.tableRow}>
                            {
                                category.items[rowIndex].icon
                                    ? (
                                        <div className={styles.cellWithIcon}>
                                            <div className={styles.cellWithIconBackground}>
                                                <a href={category.items[rowIndex].link} target={'_blank'} rel="noreferrer">
                                                    <img
                                                        alt={category.items[rowIndex].text}
                                                        src={'./pictures/technologies/' + category.items[rowIndex].text.toLowerCase().trim() + '.png'}
                                                    />
                                                </a>
                                            </div>
                                            {category.items[rowIndex].text.toString()}
                                        </div>
                                    )
                                    : category.items[rowIndex].text.toString().startsWith('https') ?
                                        <button className={styles.gitlabLinksButtons}>
                                            <a className={styles.lightGitlabLinks}
                                               href={category.items[rowIndex].text}>gitlab</a>
                                        </button> :
                                        category.items[rowIndex].text.toString()
                            }
                        </td>
                    );
                } else {
                    return <td className={styles.tableRow} key={index}/>;
                }
            });
            tableRows.push(<tr key={rowIndex} role={'tableRow'}>{rows}</tr>);
            rowIndex += 1;
        }
        return tableRows;
    };

    return (
        <div className={styles.tableContainer}>
            {children}
            <div className={styles.tableContentContainer}>
                <table role={'table'}
                       style={window.innerWidth < 600 && data[0] && data[0].title ? {display: 'block'} : {}}>
                    <thead>
                    {getTitlesFromArray()}
                    </thead>
                    <tbody>
                    {getRowsFromArray()}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Table;
