/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import axios, { AxiosRequestConfig } from 'axios';

import { getApiUrl, getAuthApiUrl } from '@environment';
import { useApiCallInterface } from '@interfaces';

export const sendRequest = async (requestConfig: useApiCallInterface) => {
    const axiosConfig = {
        method: requestConfig.method,
        url: requestConfig.authRequest ? getAuthApiUrl() + requestConfig.url : getApiUrl() + requestConfig.url,
        headers: requestConfig.headers ? requestConfig.headers : {},
        data: requestConfig.body ? requestConfig.body : {},
        responseType: 'text'
    } as AxiosRequestConfig;
    const response = await axios(axiosConfig);
    const data = response.data ? JSON.parse(response.data) : [];
    if (requestConfig.dataTransformer) {
        requestConfig.dataTransformer(data);
    }
    return data;
};