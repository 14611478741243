/*
 * Copyright (C) 2023 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@components/modal/Modal';
import { useApiCall } from '@hooks/useApiCall';
import { DeleteLTInterface, storeInterface } from '@interfaces';

import styles from './Overlays.module.css';

function DeleteLT({ handleClose, open, handleFetchLearningTechnologies, options }: DeleteLTInterface) {
    const user = useSelector((state: storeInterface) => state.user);
    const { sendRequest: deleteTechnology } = useApiCall();
    let technologyToDelete = '';

    const validation = (value: string) => {
        return value.trim().length >= 2;
    };

    const handleDeleteLearningTechnology = useCallback(async (e: SyntheticEvent) => {
        e.preventDefault();
        if (technologyToDelete && validation(technologyToDelete)) {
            try {
                await deleteTechnology({
                    url: '/technologies',
                    method: 'DELETE',
                    authRequest: false,
                    body: {
                        'name': technologyToDelete
                    },
                    headers: { token: user.token }
                });
                handleFetchLearningTechnologies(false);
                handleClose();
            } catch (error) {
                alert(error);
            }

        }
    }, [technologyToDelete, deleteTechnology, handleFetchLearningTechnologies, handleClose, validation]);

    return <Modal handleClose={handleClose} isOpen={open}>
        <div className={styles.container}>
            <input
                type={'text'}
                placeholder={'Name'}
                autoFocus={true}
                onChange={(value) => {
                    technologyToDelete = value.target.value;
                }}
                list={'options'}
                className={styles.input}
            />
            <datalist id={'options'}>
                {options.map((option) => <option key={option}>{option}</option>)}
            </datalist>
            <button id={styles.sendButton} onClick={handleDeleteLearningTechnology}>Send</button>
        </div>
    </Modal>;
}

export default DeleteLT;