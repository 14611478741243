/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const jobTimelineData = [
    {
        title: 'September 2023 - August 2024',
        cardTitle: 'Ion Beam Applications - Belgium',
        cardSubtitle: 'Full stack Software engineer',
        cardDetailedText: [
            'Working in an agile team as fullstack software engineer',
            'Existing machines not fully digitalised so the goal was to improve the chain from the hardware up to an easy to use website where customers could see the data from their machines, schedule actions, contact us,...',
            'Adding features and maintaining a website made in Angular/Typescript & Kotlin/Micronaut and hosted in Microsoft Azure.',
            'Explored infrastructure deployed to customers and improved cybersecurity and deployment of it.'
        ]
    },
    {
        title: 'June 2021 - June 2023',
        cardTitle: 'Sidero - Ireland',
        cardSubtitle: 'Full stack Software engineer',
        cardDetailedText: [
            'Consultant working for a major telecommunication company as fullstack software engineer in an agile team',
            'Adding features and maintaining a website made in Javascript & Java and sold to customers to manage their equipments',
            'Team test lead: Planning how to test newly added features while keeping previous features fully operational'
        ]
    }
];
