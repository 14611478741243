/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import Card from '@components/card/Card';
import Spinner from '@components/spinner/Spinner';
import Table from '@components/table/Table';
import { dictionary } from '@dictionary';
import { getApiUrl } from '@environment';
import { useApiCall } from '@hooks/useApiCall';
import usePagination from '@hooks/usePagination';
import { dashboardLogState, statisticsState, storeInterface } from '@interfaces';
import cache from '@utils/cache';
import {
    processAverageLogsPerDayData,
    processAverageLogsPerProjectData,
    processLogRepartitionData,
    processRawLogsData
} from '@utils/utils';

import styles from './Dashboard.module.css';

import 'chart.js/auto';

function DashboardGraphs(props: { statistics: statisticsState, areStatisticsLoading: boolean }) {
    return <div id={styles.dashboardGraphContainer}>
        <Card title={dictionary.sections.dashboard.graphTitles.logRepartitionPerProject}>
            {
                props.statistics.processedLogRepartition.datasets && props.statistics.processedLogRepartition.datasets.length > 0 && !props.areStatisticsLoading ?
                    <Doughnut
                        data={props.statistics.processedLogRepartition}
                        height="550px"
                        width="550px"
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }}
                    />
                    :
                    <Spinner/>
            }

        </Card>
        <Card title={dictionary.sections.dashboard.graphTitles.dailyAverageLogPerProject}>
            {
                props.statistics.processAverageLogsPerProject.datasets && props.statistics.processAverageLogsPerProject.datasets.length > 0 && !props.areStatisticsLoading ?
                    <Bar
                        data={props.statistics.processAverageLogsPerProject}
                        height="550px"
                        width="550px"
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false
                                }
                            }
                        }}
                    />
                    :
                    <Spinner/>
            }
        </Card>
        <Card title={dictionary.sections.dashboard.graphTitles.dailyAverageOfLogs}>
            {
                props.statistics.processAverageLogsPerDay.datasets && props.statistics.processAverageLogsPerDay.datasets.length > 0 && !props.areStatisticsLoading ?
                    <Line
                        data={props.statistics.processAverageLogsPerDay}
                        height="550px"
                        width="1100px"
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false
                                }
                            }
                        }}
                    />
                    : <Spinner/>
            }
        </Card>
    </div>;
}

function Dashboard() {
    const [logs, setLogs] = useState<dashboardLogState>({
        rawResponseData: {
            totalNumberOfLogs: 0,
            logs: []
        },
        processedLogs: [],
        totalNumberOfLogs: 0,
        numberOfPages: 0
    });
    const [statistics, setStatistics] = useState<statisticsState>({
        rawStatistics: {
            totalNumberOfLogs: 0,
            totalNumberOfProjects: 0,
            totalNumberOfDays: 0,
            numberOfLogsPerProject: [],
            numberOfLogsPerDay: [],
            averageNumberOfLogsPerProject: []
        },
        processedLogRepartition: { labels: [], datasets: [] },
        processAverageLogsPerProject: { labels: [], datasets: [] },
        processAverageLogsPerDay: { labels: [], datasets: [] }
    });

    const user = useSelector((store: storeInterface) => store.user);

    const { isLoading: areLogsLoading, sendRequest: fetchLogs } = useApiCall();
    const { isLoading: areStatisticsLoading, sendRequest: fetchStatistics } = useApiCall();
    const { currentPage, handleNextPage, handlePreviousPage, pageSize } = usePagination();

    const handleFetchingLogs = useCallback(async () => {
        try {
            const rawResponse = await fetchLogs({
                url: '/logs' + '?pageNumber=' + currentPage + '&pageSize=' + pageSize,
                method: 'GET',
                dataTransformer: processRawLogsData,
                authRequest: false,
                headers: { token: user.token }
            });

            if (rawResponse) {
                const response = processRawLogsData(rawResponse, pageSize);
                setLogs({
                    rawResponseData: response.rawResponseData,
                    processedLogs: response.processedLogs,
                    totalNumberOfLogs: response.totalNumberOfLogs,
                    numberOfPages: response.numberOfPages
                });
            }
        } catch (e) {
            alert(e);
        }
    }, [fetchLogs, processRawLogsData, setLogs, currentPage, pageSize, user]);

    const handleFetchingStatistics = useCallback(async () => {
        const firstDayOfMonth = new Date();
        firstDayOfMonth.setDate(1);
        firstDayOfMonth.setHours(0);
        firstDayOfMonth.setMinutes(0);
        firstDayOfMonth.setSeconds(0);

        const lastDayOfMonth = new Date();
        lastDayOfMonth.setHours(23);
        lastDayOfMonth.setMinutes(59);
        lastDayOfMonth.setSeconds(59);
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);

        const statisticsUrl = getApiUrl() + '/logs/statistics';
        if (!cache.get(statisticsUrl)) {
            try {
                const rawResponse = await fetchStatistics({
                    url: '/logs/statistics' + '?start=' + firstDayOfMonth.toISOString() + '&end=' + lastDayOfMonth.toISOString(),
                    method: 'GET',
                    authRequest: false,
                    headers: { token: user.token },
                });
                if (rawResponse) {
                    cache.put(statisticsUrl, rawResponse);
                    setStatistics({
                        rawStatistics: rawResponse,
                        processedLogRepartition: processLogRepartitionData(rawResponse),
                        processAverageLogsPerProject: processAverageLogsPerProjectData(rawResponse),
                        processAverageLogsPerDay: processAverageLogsPerDayData(rawResponse)
                    });
                }
            } catch (e) {
                alert(e);
            }
        } else {
            setStatistics({
                rawStatistics: cache.get(statisticsUrl),
                processedLogRepartition: processLogRepartitionData(cache.get(statisticsUrl)),
                processAverageLogsPerProject: processAverageLogsPerProjectData(cache.get(statisticsUrl)),
                processAverageLogsPerDay: processAverageLogsPerDayData(cache.get(statisticsUrl))
            });
        }
    }, [getApiUrl, cache, fetchStatistics, setStatistics, processLogRepartitionData, processAverageLogsPerProjectData, processAverageLogsPerDayData]);

    useEffect(() => {
        handleFetchingLogs().then(() => {
        });
    }, [currentPage, fetchLogs]);

    useEffect(() => {
        handleFetchingStatistics().then(() => {
        });
    }, [handleFetchingStatistics]);

    return (
        <div id={styles.dashboardContainer}>
            <div id={styles.dashboardContentContainer}>
                <div id={styles.logTableContainer}>
                    <Card>
                        <div id={styles.paginationContainer}>
                            <button className={styles.paginationContainerButton} onClick={handlePreviousPage}>Previous
                                page
                            </button>
                            <p>{currentPage + 1}/{logs.numberOfPages}</p>
                            <button className={styles.paginationContainerButton}
                                onClick={() => handleNextPage(logs.numberOfPages)}>Next page
                            </button>
                        </div>
                        {
                            logs.processedLogs.length > 0 && !areLogsLoading ?
                                <Table data={logs.processedLogs} sortByName={false}/>
                                :
                                <Spinner/>
                        }
                    </Card>
                </div>
                {
                    statistics ?
                        <DashboardGraphs statistics={statistics} areStatisticsLoading={areStatisticsLoading}/>
                        :
                        <></>
                }
            </div>
        </div>
    );
}

export default Dashboard;