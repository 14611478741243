/*
 * Copyright (C) 2023 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { SyntheticEvent, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import Modal from '@components/modal/Modal';
import { useApiCall } from '@hooks/useApiCall';
import useInputField from '@hooks/useInputField';
import { AddLTInterface, storeInterface } from '@interfaces';
import { validURL } from '@utils/utils';

import styles from '../../mastered/overlays/Overlays.module.css';

function AddLT({ handleClose, open, handleFetchLearningTechnologies }: AddLTInterface) {
    const user = useSelector((state: storeInterface) => state.user);

    const newTechnologyNameRef = useRef<HTMLInputElement>(null);
    const newTechnologyWebsiteUrlRef = useRef<HTMLInputElement>(null);
    const newTechnologyIconUrlRef = useRef<HTMLInputElement>(null);

    const { sendRequest: insertTechnology } = useApiCall();

    const {
        userValue: newTechnologyName,
        handleValueChange: newTechnologyNameOnChange,
        handleValueReset: newTechnologyNameOnReset,
        buildInputFieldCssClasses: buildTechnologyNameCssClass
    } = useInputField((value: string) => value.trim().length >= 2);

    const {
        userValue: newTechnologyWebsiteUrl,
        handleValueChange: newTechnologyWebsiteUrlOnChange,
        handleValueReset: newTechnologyWebsiteUrlOnReset,
        buildInputFieldCssClasses: buildTechnologyUrlCssClass
    } = useInputField(validURL);

    const {
        userValue: newTechnologyIconUrl,
        handleValueChange: newTechnologyIconUrlOnChange,
        handleValueReset: newTechnologyIconUrlOnReset,
        buildInputFieldCssClasses: buildTechnologyUrlIconCssClass
    } = useInputField(validURL);

    const handleInsertNewLearningTechnology = useCallback(async (e: SyntheticEvent) => {
        e.preventDefault();
        if (newTechnologyName.valid && newTechnologyWebsiteUrl.valid && newTechnologyIconUrl.valid) {
            try {
                await insertTechnology({
                    url: '/technologies',
                    method: 'POST',
                    authRequest: false,
                    body: {
                        'name': newTechnologyName.value,
                        'icon': newTechnologyIconUrl.value,
                        'link': newTechnologyWebsiteUrl.value,
                        'status': 'learning',
                        'category': ''
                    },
                    headers: { token: user.token }
                });
                handleFetchLearningTechnologies(false);
                handleClose();
            } catch (error) {
                alert(error);
            }
            newTechnologyNameOnReset();
            newTechnologyWebsiteUrlOnReset();
            newTechnologyIconUrlOnReset();
        }
    }, [newTechnologyName, newTechnologyWebsiteUrl, newTechnologyIconUrl]);

    return <Modal handleClose={handleClose} isOpen={open}>
        <form onSubmit={handleInsertNewLearningTechnology} id={styles.form}>
            <input
                type={'text'}
                className={buildTechnologyNameCssClass(newTechnologyName)}
                ref={newTechnologyNameRef}
                onChange={newTechnologyNameOnChange}
                onClick={newTechnologyNameOnReset}
                value={newTechnologyName.value}
                placeholder={'Name'}
                autoFocus={true}
            />
            <input
                type={'text'}
                className={buildTechnologyUrlCssClass(newTechnologyWebsiteUrl)}
                ref={newTechnologyWebsiteUrlRef}
                onChange={newTechnologyWebsiteUrlOnChange}
                onClick={newTechnologyWebsiteUrlOnReset}
                value={newTechnologyWebsiteUrl.value}
                placeholder={'URL'}
            />
            <input
                type={'text'}
                className={buildTechnologyUrlIconCssClass(newTechnologyIconUrl)}
                ref={newTechnologyIconUrlRef}
                onChange={newTechnologyIconUrlOnChange}
                onClick={newTechnologyIconUrlOnReset}
                value={newTechnologyIconUrl.value}
                placeholder={'Logo URL'}
            />
            <button id={styles.sendButton}>Send</button>
        </form>
    </Modal>;
}

export default AddLT;