/*
 * Copyright (C) 2022 FRANCOIS DEVILEZ
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {Chrono} from 'react-chrono';

import {jobTimelineData} from '@data';
import {dictionary} from '@dictionary';

import styles from './Experiences.module.css';

function Experiences() {
    return (
        <div id={styles.experiencesSection}>
            <div id={styles.experiencesSectionContent}>
                <p id={styles.experiencesSectionTitle}>{dictionary.sections.experiences.title}</p>
                <Chrono
                    items={jobTimelineData}
                    mode="VERTICAL_ALTERNATING"
                    contentDetailsHeight={250}
                    disableNavOnKey
                    hideControls={true}
                    theme={{
                        primary: 'grey',
                        secondary: '#729EA1',
                        cardBgColor: 'white',
                        cardForeColor: '#729EA1',
                        titleColor: 'white',
                        textColor: 'black',
                        titleColorActive: 'white'
                    }}
                    disableToolbar={true}
                />

            </div>
        </div>
    );
}

export default Experiences;